<template>
  <div class="header_area headerTop" >
    <div class="header_top">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="rightbox d-flex align-items-center" :class="device !== 'desktop' && 'm-auto'">
            <div class="box d-flex align-items-stretch" v-if="device === 'desktop'">
              <div class="footer-social">
                <ul class="list-inline d-flex">
                  <li class="list-inline-item m-0 home-icon" v-if="isNotHomePage && device === 'desktop'">
                    <router-link :to="logoURL">
                      <i class="fas fa-home"></i>
                    </router-link>
                  </li>

                  <li class="list-inline-item" v-if="showInstagramLink">
                    <a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li class="list-inline-item" v-if="showFacebookLink">
                    <a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="list-inline-item" v-if="showTwitterLink">
                    <a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-twitter"></i></a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="box d-flex align-items-stretch" v-if="device === 'desktop' && showFacebookLink"><a class="" :href="facebookLink"><p>{{ $t("top-header.follow-us") }} <i class="fas fa-cog"></i></p></a></div>

            <div class="box d-flex align-items-stretch" v-if="device === 'desktop'">
              <a class="w-100" :href="`tel:${phoneNumber}`" > <p> נופש בארץ <img src="assets/img/theme1/phone.png" alt="#"> {{ phoneNumber }} </p></a>
            </div>

            <div class="box d-flex align-items-stretch" v-if="device === 'desktop' && showWhatsappNumber">
              <a class="w-100" :href="`tel:${whatsappNumber}`" ><p>  {{ $t("top-header.whatsapp") }} : <img src="assets/img/theme1/phone.png" alt="#">{{ whatsappNumber }} </p></a>
            </div>
            <div class="box d-flex align-items-stretch" v-if="device === 'desktop' && isBYTE">
              <p>לביטול/שינוי הזמנה בלבד</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal id="agentListForPricePlan" centered :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("price-plan.agency-list") }}</h3>
        </div>
      </template>
      <template>
        <div class="w-100 agent-list" v-if="agencyListForPricePlan && agencyListForPricePlan.length">
          <b-list-group>
            <!-- <b-list-group-item class="my-1" v-for="(agency, inx) in agencyListForPricePlan" :key="inx" :href="`?${agency.homeUrl.split('?')[1]}&fc-agent-mode`" variant="primary"> -->
            <b-list-group-item class="my-1" v-for="(agency, inx) in agencyListForPricePlan" :key="inx" :href="`${agency.homeUrl}&fc-agent-mode`" variant="primary">
              <div class="agency-code">{{ agency.odyCode }}</div>
              <!-- <img class="mx-1" alt="after-booking" :src="agency.homeContent ? agency.homeContent.logoUrls: 'https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://image.flyingcarpet.co.il/upload/image/logo/logo_20210421185205432.png'" width="50" height="50"/> -->
            <img class="mx-1" alt="after-booking" :src="agency.homeContent ? agency.homeContent.logoUrls: 'https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://image.flyingisr.com/upload/image/logo/logo_20210421185205432.png'" width="50" height="50"/>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-else class="w-100 text-center">no-content</div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="ok();">
          {{ $t("login.cancel") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue';
import TemplateTopHeader from './TemplateTopHeader';

export default {
  name: 'TopHeaderTheme1',
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    // countdown: () => import('@/components/atoms/CountDown'),
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
  },
  extends: TemplateTopHeader,
};
</script>
<style scoped>
li.home-icon {
  height: 24px;
  border-right: none !important;
}
li.home-icon > a {
  font-size: 1.2rem;
}
li.phone-number-rtl {
  direction: ltr;
}
ul {
  font-family: "Poppins", sans-serif;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}
.landing-part .logo {
  max-width: 230px;
  float: left;
  margin-left: 6rem;
}
.landing-part ul li img {
  max-width: 34px;
  margin-right: 5px;
}
.landing-part ul {
  line-height: 40px;
}
.landing-part .header_rightbox_bottom {
  display: block;
  background-color: #fff;
  padding: 8px 20px 9px 30px;
  position: absolute;
  top: 50px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
  font-size: 1.2em;
}
.landing-part .header_rightbox_bottom a {
  color: #0061ab;
}
.landing-part .header_rightbox_bottom:before {
  content: "";
  position: absolute;
  left: -45px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 45px solid white;
  border-left: 45px solid transparent;
  z-index: 0;
}

.topFollowUs > a:hover, .topCallUs > a:hover {
  color: #adf;
}

.footer-social ul {
    margin-top: 0.7em;
    text-align: center;
    margin-bottom: 0.5em;
}
.footer-social ul li a {
  color: #0061ab;
  background: #fff;
  width: 2em;
  height: 2em;
  font-size: 1.2em;
  line-height: 1;
  margin: 0.3em 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 13%);
}
.footer-social ul li a:hover{
    background-color: #0061ab;
    color: #fff;
}

.headerTop.header_area{ position: relative; }
.headerTop.header_area .header_top{ position: relative; background-color: #1993C8;}
.headerTop.header_area .header_top .accessibility_btn {
    color: #1993C8;
    font-size: 14px;
    border: none;
    padding: 11px 24px;
}
.headerTop.header_area .header_top .accessibility_btn img {
    margin-right: 8px;
}
.headerTop.header_area .header_top .rightbox .box {
    border-right: 1px solid rgb(255 255 255 / 38%);
    padding: 0px 18px;
    min-height: 40px;
    display: flex!important;
    align-items: center!important;
}
.headerTop.header_area .header_top .rightbox .box:last-child{ border-left:1px solid rgb(255 255 255 / 38%); }
.headerTop.header_area .header_top .rightbox .box p{ margin-bottom:0; font-size:14px; color:#fff; font-weight:400; }
.headerTop.header_area .header_top .rightbox .box p i {
    margin-right: 6px;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
}
.headerTop.header_area .header_top .rightbox .box p img{ margin:0 5px; }
.headerTop.header_area .header_top .rightbox .box .footer-social ul{ margin:0; }
.headerTop.header_area .header_top .rightbox .box .footer-social ul li a {
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    font-size: 14px;
    margin: 0;
}
.headerTop.header_area .header_top .rightbox .box .footer-social ul li a:hover{
    background-color: #fff;
    color: #1993C8;
}

@media (max-width:991.98px){
  .headerTop.header_area .header_top {
      display: none;
  }
}

@media (max-width: 767px) {
  ul {
    padding: 0;
  }
  .landing-part .logo {
    margin: 20px 0 35px 3rem;
    max-width: 160px;
  }
 .landing-part .header_rightbox_bottom {
    display: initial;
    top: 70px;
  }
}

@media (max-width:410px){
 .footer-social ul li a {
      font-size: 16px;
  }
}
</style>
<style>

.he-alert {
  direction: rtl;
  text-align: right;
  padding-left: 4rem;
  padding-right: 1rem;
}
.he-alert button.close {
  left: 0;
  right: auto;
}
.he-result-body p {
  direction: rtl;
  text-align: right;
}
div[class*='result-body'] p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.headerTop-links .btn.dropdown-toggle{
  background: #0061ab;
  border: none;
  padding: 0;
  font-size: inherit;
}
.headerTop-links .btn.dropdown-toggle:hover{
  color: yellow;
}
</style>
